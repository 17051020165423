"use client";

import { usePlayingPopup } from "@/hooks";
import gamelist from "@/hooks/fetchers/useGame";
import usePromotionHistory from "@/hooks/fetchers/usePromotionHistory";
import useLaunch from "@/hooks/useLaunch";
import useTransferWallet from "@/hooks/useTransferWallet";
import useProvidersList from "@/hooks/useProvidersList";
import { useAppModalStore } from "@/stores/appModalStore";
import { useAuthStore } from "@/stores/authStore";
import {
  Box,
  Card,
  CardMedia,
  CardMediaProps,
  CircularProgress,
  Grid,
  InputAdornment,
  TextField,
  Typography,
  IconButton
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useParams, useRouter } from "next/navigation";
import { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { shallow } from "zustand/shallow";
import Close from '@mui/icons-material/Close';

const Item = styled(Box)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const ItemImage = styled(CardMedia)<CardMediaProps>(({ theme }) => ({
  borderRadius: "16px",
  transition: "all 0.12s ease-in-out",
  "&:hover": {
    transform: "scale(1.08)",
    cursor: "pointer",
  },
}));

export default function GamesList() {
  const { theme } = useProvidersList();
  const { launchGame, loading: isGameLaunchLoading } = useLaunch();
  // const { handleTransfer } = useTransferWallet();
  const { isPlayingPopup } = usePlayingPopup();
  const [setOpen, setData] = useAppModalStore(
    (state) => [state.setOpen, state.setData],
    shallow
  );
  const router = useRouter();
  const params: any = useParams();
  const { data: gameList, isLoading: isGameListLoading } = gamelist(
    params.slug
  );

  const { data: promotion } = usePromotionHistory();
  const [isLoggedIn] = useAuthStore(
    (state) => [state.user!, state.logout],
    shallow
  );

  const [searchString, setSearchString] = useState('')

  const activePromotionPlatform = promotion?.promotion?.platform?.split(",") || [];

  if (
    ![...activePromotionPlatform].includes(params.slug || "") &&
    isLoggedIn &&
    promotion
  ) {
    router.push("/");
  }

  useEffect(() => {
    if (isPlayingPopup) {
      setData("playingPopup");
      setOpen(true);
    }
  }, [isPlayingPopup]);

  if (isGameListLoading) {
    return (
      <>
        <Grid sx={{ textAlign: "center" }}>
          <CircularProgress></CircularProgress>
        </Grid>
      </>
    );
  } else {
    // const customGameList = (params?.slug.toUpperCase() === 'KINGMAKER' || params?.slug.toUpperCase() === 'JKR')
    //   ? []
    //   : searchString?.length === 0
    const customGameList = (
      params?.slug.toUpperCase() === 'JKR' ||
      params?.slug.toUpperCase() === 'FASTSPIN' ||
      params?.slug.toUpperCase() === 'KINGMAKER' ||
      // params?.slug.toUpperCase() === 'SPADE' ||
      params?.slug.toUpperCase() === 'PT'
    )
      ? []
      : searchString?.length === 0
        ? gameList
        : gameList?.filter(game => {
          const gameName = game.match_name
            ? game.match_name
            : game.name.toLowerCase().replaceAll(' ', '')

          return gameName.includes(searchString.toLowerCase().replaceAll(' ', ''))
        })

    return (
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={0}>
          {(
            !["preset13", "preset14", "preset16", "preset18", "preset19", "preset20", "preset21", "preset23", "preset24", "preset25", "preset26", "preset27", "preset17"].find(name => name === theme.name)
          ) && (
              <Grid item xs={12} sx={{ pt: 1 }}>
                <Typography variant="h6">
                  {params.slug} | ทั้งหมด {gameList?.length} เกมส์
                </Typography>
              </Grid>
            )}

          <Grid item xs={12} md={12} mb={2}>
            <TextField
              fullWidth
              autoComplete="off"
              autoCorrect="off"
              aria-readonly
              id="search"
              label="ค้นหา"
              value={searchString}
              onChange={(e) => {
                setSearchString(e.target.value)
              }}
              placeholder="ค้นหา"
              InputProps={{
                endAdornment: searchString?.length !== 0 ? (
                  <InputAdornment position="end">
                    <IconButton
                      style={{
                        border: "1.5px solid white",
                        height: "28px",
                        width: "28px",
                      }}
                      aria-label="clear"
                      aria-controls="menu-appbar"
                      aria-haspopup="true"
                      onClick={() => {
                        setSearchString("");
                      }}
                      color="inherit"
                    >
                      <Close style={{ fontSize: "18px" }} />
                    </IconButton>
                  </InputAdornment>
                ) : null
              }}
            />
          </Grid>
          {customGameList?.map((game: any, key: number) => {
            if (!game?.img_custom) {
              return (
                <Grid key={key} item md={2} xs={4}>
                  <Item>
                    <Card>
                      <ItemImage
                        component="img"
                        image={game?.img}
                        title={game?.name}
                        onClick={() => {
                          if (game?.agent_name === "BETFLIK") {
                            launchGame(
                              game?.id || "",
                              isMobile,
                              game?.is_iframe
                            );
                          } else {
                            launchGame(
                              game?.id || "",
                              isMobile
                            );
                          }
                        }}
                        style={{
                          width: "100%",
                          height: "auto",
                          filter: isGameLaunchLoading
                            ? "grayscale(90%)"
                            : "grayscale(0%)",
                        }}
                      />
                    </Card>
                    <Typography
                      sx={{
                        mt: 1,
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                      variant="body2"
                      color={"white"}
                    >
                      {game?.name ?? "N/A"}
                    </Typography>
                  </Item>
                </Grid>
              );
            } else {
              return (
                <Grid key={key} item md={2} xs={4}>
                  <Item>
                    <Card>
                      <ItemImage
                        component="img"
                        image={game?.img_custom}
                        title={game?.name}
                        onClick={() => {
                          if (game?.agent_name === "BETFLIK") {
                            setOpen(true);
                            launchGame(
                              game?.id || "",
                              isMobile,
                              game?.is_iframe
                            );
                          } else {
                            launchGame(
                              game?.id || "",
                              isMobile
                            );
                          }
                        }}
                        style={{
                          width: "100%",
                          height: "auto",
                          filter: isGameLaunchLoading
                            ? "grayscale(90%)"
                            : "grayscale(0%)",
                        }}
                      />
                    </Card>
                    <Typography
                      sx={{
                        mt: 1,
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                      variant="body2"
                      color={"white"}
                    >
                      {game?.name ?? "N/A"}
                    </Typography>
                  </Item>
                </Grid>
              );
            }
          })}
        </Grid>
      </Box>
    );
  }
}

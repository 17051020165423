"use client";

import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import Box from "@mui/material/Box";

import { LoadingButton } from "@mui/lab";

import { useAppModalStore } from "@/stores/appModalStore";
import { useAuthModalStore } from "@/stores/authModalStore";
import { useAuthStore } from "@/stores/authStore";
import { yupResolver } from "@hookform/resolvers/yup";
import { useRouter } from "next/navigation";
import { useSnackbar } from "notistack";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import * as yup from "yup";
import { shallow } from "zustand/shallow";
import { Icon } from "@iconify/react";
import { useState } from "react";
import { Phone } from "@mui/icons-material";

type Inputs = {
  username: string;
  password: string;
};

const schema = yup.object().shape({
  username: yup
    .string()
    .matches(/^[0-9]+$/, "เบอร์โทรศัพท์ต้องเป็นตัวเลขเท่านั้น")
    .min(6, "เบอร์โทรศัพท์ต้องมีอย่างน้อย 10 ตัวอักษร")
    .max(10, "เบอร์โทรศัพท์ต้องมีไม่เกิน 10 ตัวอักษร")
    .required("กรุณากรอกเบอร์โทรศัพท์"),
  password: yup
    .string()
    .min(6, "รหัสผ่านต้องมีอย่างน้อย 6 ตัวอักษร")
    .max(30, "รหัสผ่านต้องมีไม่เกิน 30 ตัวอักษร")
    .required("กรุณากรอกรหัสผ่าน"),
});

type Props = {
  onFinish?: () => void;
};

export default function Login({ onFinish = () => { } }: Props) {
  const route = useRouter();
  const { enqueueSnackbar } = useSnackbar();
  const [setOpenAppModal, setAppDataModal] = useAppModalStore(
    (state) => [state.setOpen, state.setData],
    shallow
  );

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>({ resolver: yupResolver(schema) });

  const [setOpenLoginModal] = useAuthModalStore(
    (state) => [state.setOpenLoginModal],
    shallow
  );

  const [login, loading, setLoading] = useAuthStore(
    (state) => [state.login, state.loading, state.setLoading, state.setUser],
    shallow
  );

  const [showPassword, setShowPassword] = useState(false)

  const onSubmit: SubmitHandler<Inputs> = (data) => {
    setLoading(true);
    const { username, password } = data;
    login(username, password)
      .then((res: any) => {
        if (res?.errMessage) {
          enqueueSnackbar(res.errMessage, {
            variant: "error",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
          });
        } else {
          route.push("/");
          // enqueueSnackbar("เข้าสู่ระบบสำเร็จ", {
          //   variant: "success",
          //   anchorOrigin: {
          //     vertical: "top",
          //     horizontal: "right",
          //   },
          // });
          onFinish();
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        enqueueSnackbar("เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง", {
          variant: "error",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        });
      });
  };

  const handleClickForgot = () => {
    setOpenLoginModal(false);
    setAppDataModal("forgotPassword");
    setOpenAppModal(true);
  };

  return (
    <Box p={2} component={"form"} onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <FormControl fullWidth>
            <Controller
              name="username"
              control={control}
              rules={{ required: true }}
              render={({ field: { value, onChange, onBlur } }) => (
                <TextField
                  id="username"
                  // autoFocus
                  label="เบอร์โทรศัพท์"
                  sx={{
                    ...(errors.password && {
                      borderColor: (theme) => theme.gradient[900] || "error.main",
                      borderStyle: "solid",
                      borderWidth: 1,
                      borderRadius: "16px",
                      "& .MuiInputLabel-root": {
                        color: (theme) => theme.gradient[900] || "error.main",
                      }
                    })
                  }}
                  value={value}
                  onBlur={onBlur}
                  onChange={(e) => {
                    const numericValue = e.target.value.replace(/\D/g, "");
                    onChange(numericValue);
                  }}
                  error={Boolean(errors.username)}
                  placeholder="เบอร์โทรศัพท์"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <IconButton
                          style={{
                            // border: "1.5px solid white",
                            height: "28px",
                            width: "28px",
                          }}
                          aria-label="clear"
                          aria-controls="menu-appbar"
                          aria-haspopup="true"
                          color="inherit"
                        >
                          <Phone style={{ fontSize: "18px" }} />
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />
              )}
            />
            {errors.username && (
              <FormHelperText
                sx={{
                  ...(errors.password && {
                    borderColor: (theme) => theme.gradient[900] || "error.main",
                  })
                }}>
                {errors.username.message}
              </FormHelperText>
            )}
          </FormControl>
        </Grid>
        <Grid item xs={12} md={12}>
          <FormControl fullWidth>
            <Controller
              name="password"
              control={control}
              rules={{ required: true }}
              render={({ field: { value, onChange, onBlur } }) => (
                <TextField
                  id="password"
                  label="รหัสผ่าน"
                  sx={{
                    ...(errors.password && {
                      borderColor: (theme) => theme.gradient[900] || "error.main",
                      borderStyle: "solid",
                      borderWidth: 1,
                      borderRadius: "16px",
                      "& .MuiInputLabel-root": {
                        color: (theme) => theme.gradient[900] || "error.main",
                      }
                    })
                  }}
                  type={showPassword ? "text" : "password"}
                  // value={value}
                  onBlur={onBlur}
                  onChange={onChange}
                  error={Boolean(errors.password)}
                  placeholder="รหัสผ่าน"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="clear"
                          aria-controls="menu-appbar"
                          aria-haspopup="true"
                          onClick={() => {
                            setShowPassword((prevState) => !prevState)
                          }}
                          color="inherit"
                        >
                          <Icon
                            id="icon"
                            icon={showPassword ? "mdi:eye-off-outline" : "mdi:eye-outline"}
                            width={24}
                            height={24}
                          />
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />
              )}
            />
            {errors.password && (
              <FormHelperText
                sx={{
                  ...(errors.password && {
                    borderColor: (theme) => theme.gradient[900] || "error.main",
                  })
                }}>
                {errors.password.message}
              </FormHelperText>
            )}
          </FormControl>
        </Grid>
        {/* <Grid item xs={12} md={12}>
          <FormControlLabel control={<Checkbox defaultChecked />} label="จำรหัสผ่าน" />
          <Stack direction="row" justifyContent="space-between">
            <FormControlLabel control={<Checkbox defaultChecked />} label="จำรหัสผ่าน" sx={{ color: (theme) => theme.gradient[900] }} />
            <FormControlLabel
              onClick={() => handleClickForgot()}
              control={<Typography></Typography>}
              label="ลืมรหัสผ่าน?" sx={{ color: (theme) => theme.gradient[900] }}
            />
          </Stack>
        </Grid> */}
        <Grid item xs={12} md={12}>
          <LoadingButton
            type="submit"
            variant="contained"
            fullWidth
            loading={loading}
            sx={{
              color: (theme) => theme.name === 'preset14' ? '#fff' : theme.gradient[900],
              backgroundColor: (theme) => theme.name === 'preset14' ? '#ffc100' : 'default',
              height: "50px",
              "&:hover": {
                color: (theme) => theme.name === 'preset14' ? '#fff' : 'default',
                backgroundColor: (theme) => theme.name === 'preset14' ? '#ffc100' : 'default',
              },
            }}
          >
            เข้าสู่ระบบ
          </LoadingButton>
        </Grid>
        <Grid item xs={12} md={12}>
          <Button
            variant="outlined"
            fullWidth
            onClick={() => handleClickForgot()}
            sx={{
              color: (theme) => theme.name === 'preset14' ? '#fff' : theme.gradient[900],
              backgroundColor: (theme) => theme.name === 'preset14' ? '#ffc100' : 'default',
              height: "50px",
              "&:hover": {
                color: (theme) => theme.name === 'preset14' ? '#fff' : 'default',
                backgroundColor: (theme) => theme.name === 'preset14' ? '#ffc100' : 'default',
              },
            }}
          >
            ลืมรหัสผ่าน
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}

"use client";

import React, { CSSProperties } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import {
  Box,
  Grid,
  CircularProgress,
  useMediaQuery,
  useTheme,
  Skeleton,
  Typography,
} from "@mui/material";
import useBannerSlider from "@/hooks/fetchers/useBannerSlider";
import "./style.css";
import { useAuthStore } from "@/stores/authStore";
import { shallow } from "zustand/shallow";
import { useAuthModalStore } from "@/stores/authModalStore";
import { useAppModalStore } from "@/stores/appModalStore";

type Props = {};

const indicatorStyles: CSSProperties = {
  background: "#fff",
  display: "inline-block",
  margin: "0 8px",
  cursor: 'pointer'
};

export default function BannerCarousel({ }: Props) {
  const theme = useTheme();
  const [isLoggedIn] = useAuthStore((state) => [state.user], shallow);
  const [setOpenLoginModal] = useAuthModalStore((state) => [state.setOpenLoginModal], shallow);
  const [setOpenAppModal, setData] = useAppModalStore((state) => [
    state.setOpen,
    state.setData,
  ]);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const { data: bannersliders, isLoading: isBannerSliderLoading } =
    useBannerSlider();

  if (isBannerSliderLoading) {
    return (
      <>
        <Grid sx={{ textAlign: "center" }}>
          <Skeleton variant="rounded" width={'auto'} height={isMobile ? '9rem' : '18.25rem'} />
        </Grid>
      </>
    );
  } else {
    return (
      <Carousel
        interval={isMobile ? 0 : 6000}
        infiniteLoop={isMobile ? false : true}
        autoPlay={isMobile ? false : true}
        showArrows={false}
        centerMode
        showStatus={false}
        showThumbs={false}
        centerSlidePercentage={isMobile ? 92.5 : 100}
        preventMovementUntilSwipeScrollTolerance={true}
        swipeScrollTolerance={50}
        stopOnHover={false}
        showIndicators={isMobile ? false : true}
        renderIndicator={(onClickHandler, isSelected, index, label) => {
          if (isSelected) {
            return (
              <li
                style={{
                  ...indicatorStyles,
                  borderRadius: theme.shape.borderRadius,
                  background: theme.palette.primary.contrastText,
                  width: 28,
                  height: 8,
                }}
                aria-label={`Selected: ${label} ${index + 1}`}
                title={`Selected: ${label} ${index + 1}`}
              />
            );
          }

          return (
            <li
              style={{
                ...indicatorStyles,
                borderRadius: theme.shape.borderRadius,
                background: theme.palette.primary.main,
                width: 8,
                height: 8
              }}
              onClick={onClickHandler}
              onKeyDown={onClickHandler}
              value={index}
              key={index}
              role="button"
              tabIndex={0}
              title={`${label} ${index + 1}`}
              aria-label={`${label} ${index + 1}`}
            />
          );
        }}
      >
        {bannersliders?.map((banner) => (
          <Box
            key={banner?.desc}
            mx={{
              xs: 0,
              md: 1,
            }}
            sx={{
              cursor: 'pointer'
            }}
            onClick={() => {
              if (!isLoggedIn) {
                // setOpenLoginModal(true)
                return
              } else {
                switch (banner.desc) {
                  case "ฝากถอนระบบออโต้":
                    setData('deposit');
                    setOpenAppModal(true);
                    return;

                  case "โบนัสล้อนำโชค":
                    setData('wheel');
                    setOpenAppModal(true);
                    return;

                  case 'เล่นเสียรับเงินคืน':
                    setData('refund');
                    setOpenAppModal(true);
                    return;

                  case 'เดิมพันสล็อต รับโบนัสทุกวัน':
                    setData('promotion');
                    setOpenAppModal(true);
                    return;

                  default:
                    return;
                }
              }
            }}
          >
            <img
              style={{
                width: isMobile ? '97.5%' : '100%',
                borderRadius: "16px",
                cursor: 'pointer'
              }}
              src={isMobile ? banner?.image.replace('.jpg', '_m.jpg') : banner?.image}
            />
            {
              isLoggedIn && (
                <Typography
                  variant="h6"
                  fontWeight={"500"}
                  align="center"
                  color={'#0d1117'}
                  sx={{
                    fontSize: '0.85rem',
                    fontWeight: '600',
                    position: 'absolute',
                    padding: '1px 8px',
                    borderRadius: '10px',
                    color: '#fefefe',
                    background: '#1a2c38;',
                    mt: 1,
                    ml: 1.5,
                    top: 0,
                    left: 0,
                  }}
                >
                  {`คลิก`}
                </Typography>
              )
            }
          </Box>
        ))}
      </Carousel>
    );
  }
}
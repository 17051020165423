"use client";

import { useEffect, useState } from "react";
import useProvidersList from "@/hooks/useProvidersList";
import { useConfigStore } from "@/stores/configStore";
import { shallow } from "zustand/shallow";
import { Box, CircularProgress, Grid, Skeleton, Typography, useMediaQuery, styled, CardMediaProps, CardMedia, Card } from "@mui/material";
import GameMenu from "./GameMenu";
import DepositAndWithdrawButtonGroup from "./DepositAndWithdrawButtonGroup";
import MainMenu from "./MainMenu";
import { LobbyCarousel } from "@/components/carousel";
import SectionList from "../components/carousel/SectionList";

type Game = {
  category: string;
  name: string;
  code: string;
  status: boolean;
  display: boolean;
  image: string;
  image_newlobby: string;
  image_modify: string;
  is_lobby: boolean;
  games_lobby: any[];
};

const CATEGORY: {
  [key: string]: string;
} = {
  slot: "สล็อต",
  graph: "กราฟ",
  casino: "คาสิโนสด",
  card: "เกมไพ่",
  lottery: "หวย",
  sport: "กีฬา",
};

const Item = styled(Box)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const GameImage = styled(CardMedia)<CardMediaProps>(({ theme }) => ({
  borderRadius: "16px",
  transition: "all 0.12s ease-in-out",
  "&:hover": {
    transform: "scale(1.08)",
    cursor: "pointer",
  },
}));


export default function LobbyList() {
  const {
    gameId,
    gameList,
    groupedArray,
    isGameListLoading,
    theme,
    handleProviderClick,
    handleRightMenuClick,
    isLoggedIn,
    width,
    height,
    ItemImage,
    isActive,
    activePromotionPlatform,
    router,
    launchGame,
    isGameLaunchLoading
  } = useProvidersList();
  const [category, setCategory] = useState(0)
  const [config] = useConfigStore((state) => [state.config], shallow);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const customAgentList = ['tiga98', 'funee8', 'ehub8', 'rvp8', 'v7heng', 'gax88', 'bcv789']
  const isCustomAgentPreset = customAgentList.find(agent => agent === config?.agent_name)

  useEffect(() => {
    if (!isGameLaunchLoading && gameId === '') {
      window.scrollTo(0, 1)
    }
  }, [isGameLaunchLoading])

  if (isGameListLoading) {
    return (
      <>
        <Grid
          sx={{ textAlign: "center", pt: 3, pb: 3 }}
        >
          <GameMenu category={category} setCategory={setCategory} />
        </Grid>
        <Grid sx={{ textAlign: "center" }}>
          {
            Array(10).fill(0).map((_val, index) => {
              return <Skeleton key={index} animation="wave" />
            })
          }
        </Grid>
      </>
    );
  } else {
    const lobbyList = [] as any
    return (
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={0}>
          {/* {isLoggedIn && (
            <>
              <Grid
                item
                xs={12}
                md={12}
                sx={{ pt: 3, pb: 0 }}
              >
                <GameMenu category={category} setCategory={setCategory} />
              </Grid>
              <Grid
                item
                xs={12}
                md={12}
                sx={{ pt: 3, pb: 0 }}
              >
                <MainMenu />
              </Grid>
            </>
          )} */}
          <Grid
            item
            xs={12}
            md={12}
            sx={{ pt: 2, pb: 0 }}
          >
            <GameMenu category={category} setCategory={setCategory} />
          </Grid>
          {
            category === 0
              ? lobbyList
                .concat({
                  type: 'provider',
                  name: 'casino',
                  label: 'Provider คาสิโน',
                  path: 2,
                  isShowActive: false,
                  isShowAll: false,
                  minSize: 3,
                  maxSize: 6,
                  minGrid: 4,
                  maxGrid: 2,
                  spacing: 1,
                  list: groupedArray.find((array: any) => array.category === 'casino')?.data.map((array: any) => {
                    return {
                      ...array,
                      onClick: () => {
                        handleProviderClick(array.game_id, array.game, array.category, array.platform, true, "")
                      }
                    }
                  })
                })
                .concat(gameList?.lobby_list.slice(0, 1))
                .concat({
                  type: 'provider',
                  name: 'sport',
                  label: 'Provider กีฬา',
                  path: 3,
                  isShowActive: false,
                  isShowAll: false,
                  minSize: 2,
                  maxSize: 6,
                  minGrid: 6,
                  maxGrid: 2,
                  spacing: 1,
                  list: groupedArray.find((array: any) => array.category === 'sport')?.data.map((array: any) => {
                    return {
                      ...array,
                      onClick: () => {
                        handleProviderClick(array.game_id, array.game, array.category, array.platform, true, "")
                      }
                    }
                  })
                })
                .concat(gameList?.lobby_list.slice(1))
                .concat({
                  type: 'provider',
                  name: 'slot',
                  label: 'Provider สล็อต',
                  path: 1,
                  isShowActive: false,
                  isShowAll: false,
                  minSize: 6,
                  maxSize: 6,
                  minGrid: 4,
                  maxGrid: 2,
                  spacing: 0.75,
                  list: groupedArray.find((array: any) => array.category === 'slot')?.data.map((array: any) => {
                    return {
                      ...array,
                      image: `https://images-platform.sgp1.cdn.digitaloceanspaces.com/gamepartners/tab/${array.code}.png`
                    }
                  })
                })
                .map((section: any, index: number) => {
                  return (
                    <SectionList
                      key={index}
                      section={section}
                      router={router}
                      isLoggedIn={isLoggedIn}
                      isActive={isActive}
                      activePromotionPlatform={activePromotionPlatform}
                      handleRightMenuClick={handleRightMenuClick}
                      launchGame={launchGame}
                      isGameLaunchLoading={isGameLaunchLoading}
                      setCategory={setCategory}
                    />
                  )
                })
              : groupedArray
                .filter((item) => {
                  const match = {
                    0: 'lobby',
                    1: 'slot',
                    2: 'casino',
                    3: 'sport',
                    4: 'lottery'
                  } as any

                  return item.category === match[category]
                })
                .map((item, index) => (
                  <Grid
                    id={`category-${item.category}`}
                    key={`category-${item.category}`}
                    item
                    xs={12}
                    sx={{ pt: 2, pb: 0 }}
                  >
                    {/* {
                  category === 0 && (
                    <Typography
                      variant="h5"
                      sx={{ color: (theme) => theme.gradient[700] || "white" }}
                    >
                      {CATEGORY?.[item.category] || "~"}
                    </Typography>
                  )
                } */}
                    <Box
                      mt={-0.5}
                      borderRadius={8}
                      width={"6px"}
                      height={"4px"}
                    // bgcolor={category === 0 ? theme.palette.primary.main : 'none'}
                    />
                    <Grid
                      container
                      columns={{
                        xs: 12,
                        sm: 12,
                        md: 6,
                        lg: 6
                      }}
                      spacing={1}
                      justifyContent={"space-start"}
                      alignItems={"center"}
                    >
                      {item.data.map((game) => {
                        return (
                          <Grid
                            key={game.code}
                            item
                            // xs={category === 0 ? 6 : 3}
                            // xs={category === 0 ? 3 : 2}

                            xs={4}
                            sm={4}
                            md={1}
                            lg={1}
                            position="relative"
                          >
                            <Box
                              onClick={
                                !isLoggedIn
                                  ? () => {
                                    handleRightMenuClick();
                                  }
                                  : undefined
                              }
                            >
                              <Box
                                style={{
                                  textDecoration: "none",
                                  color: theme.palette.primary.main,
                                  textAlign: "center",
                                }}
                                onClick={() => {
                                  if (game.name === 'WS Sports' || game.name === 'SABA Sports') {
                                    handleProviderClick(
                                      "9728",
                                      'UFA',
                                      'sport',
                                      "UFA",
                                      false,
                                      ""
                                    );
                                  } else {
                                    handleProviderClick(
                                      game?.id || "",
                                      game?.code || "",
                                      game?.category || "",
                                      game?.code || "",
                                      game?.games_lobby?.[0]?.is_iframe ?? true,
                                      game?.code_betflik || ""
                                    );
                                  }
                                }}
                              >
                                <ItemImage
                                  placeholder={"blur"}
                                  blurDataURL="/assets/icons/category-casino.png"
                                  src={
                                    game?.image
                                  }
                                  width={width}
                                  height={height}
                                  alt={game.code}
                                  style={{
                                    width: "100%",
                                    height: "auto",
                                    filter:
                                      isLoggedIn &&
                                        isActive &&
                                        !activePromotionPlatform.includes(
                                          game?.code || ""
                                        )
                                        ? "grayscale(90%)"
                                        : "grayscale(0%)",
                                  }}
                                />
                                <Typography>
                                </Typography>
                              </Box>
                            </Box>
                          </Grid>
                        )
                      })}
                    </Grid>
                  </Grid>
                ))
          }
        </Grid>
      </Box>
    );
  }
}
